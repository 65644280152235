<template>
    <div class="safe_input">
        <el-input class="input" :value="value" autofocus :maxlength="max" @input="handleInput" @blur="isFocus = false" @focus="isFocus = true"></el-input>
        <div v-for="(item,index) in max" :key="index" class="safe_input_box" :class="value.length === index && isFocus?'active':''">
            <div v-if="value[index] && !isShow" class="point"></div>
            <div v-if="isShow">{{ value[index] }}</div>
            <div v-if="value.length === index && isFocus" class="cursor"></div>
        </div>
    </div>
</template>
  
<script>
    export default {
        name: 'safeInput',
        props: {
            value: {
                type: String,
                default: ''
            },
            max: {
                type: [String,Number],
                default: 6
            },
            isShow: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                isFocus: false
            }
        },
        created(){

        },
        methods:{
            handleInput(e){
                this.$emit('input', e)
            }
        }
    }
</script>
  
<style lang="scss" scoped>
    *{
        box-sizing: border-box;
        line-height: 1;
    }
    .safe_input{
        display: flex;
        align-items: center;
        position: relative;
        .input{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
            opacity: 0;
        }
        .safe_input_box{
            width: 60px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 5px;
            border: 1px solid #EAEAEA;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            position: relative;
            font-weight: bold;
            font-size: 18px;
            &.active{
                border-color: #016EF1;
            }
            &:last-child{
                margin-right: 0;
            }
            .point{
                width: 10px;
                height: 10px;
                background: #333333;
                border-radius: 50%;
            }
            .cursor{
                width: 1px;
                height: 70%;
                background: #016EF1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                animation: blink 1s linear infinite; /* 持续时间为1秒，线性过渡，无限次循环 */
            }
            @keyframes blink{
                0%,100%{
                    opacity: 1;
                }
                50%{
                    opacity: 0;
                }
            }
        }
    }
</style>
  