<template>
    <div class="safe_common safe_psw" @click.stop>
        <img @click="handClose" v-if="showClose" class="close_icon cur_p" src="../assets/images/close.png" alt="">
        <!-- 验证手机号 -->
        <template v-if="safeType == 2">
            <div class="safe_common_title">手机号验证</div>
            <div class="safe_common_subtitle">为确保您的账号安全，我们将进行下列验证事项</div>
            <div class="common_phone">
                <div class="flex1">{{hideMiddleFour(userInfo.userName)}}</div>
                <div @click="handCode" class="safe_countdown">{{countdown > 0? `重新获取${countdown}s`:'获取验证码'}}</div>
            </div>
            <safe-input class="mgt-20" v-model="code" :isShow="true" :max="4"></safe-input>
            <div class="flex ai_center jc_end mgt-20">
                <div v-if="!showClose" @click="handBack" class="safe_btn">上一步</div>
                <div @click="checkCode" class="safe_btn safe_btn2" :class="code.length==4?'primary':''">下一步</div>
            </div>

        </template>
        <!-- 输入新密码 -->
        <template v-if="safeType == 3">
            <div class="safe_common_title">修改安全密码</div>
            <div class="safe_common_subtitle">请输入新的安全密码，用于查看验证</div>
            <safe-input class="mgt-20" v-model="editPsw" :max="6"></safe-input>
            <div class="safe_rule">不能是登录密码或连续数字</div>
            <div class="flex ai_center jc_end mgt-30">
                <div @click="handLast('editPsw')" class="safe_btn">上一步</div>
                <div @click="handNext('editPsw')" class="safe_btn safe_btn2" :class="editPsw.length==6?'primary':''">下一步</div>
            </div>

        </template>
        <!-- 输入新密码 -->
        <template v-if="safeType == 4">
            <div class="safe_common_title">确认安全密码</div>
            <div class="safe_common_subtitle">再次输入新的安全密码，用于查看验证</div>
            <safe-input class="mgt-20" v-model="editRepeatPsw" :max="6"></safe-input>
            <div class="safe_rule">不能是登录密码或连续数字</div>
            <div class="flex ai_center jc_end mgt-30">
                <div @click="handLast('editRepeatPsw')" class="safe_btn">上一步</div>
                <div @click="forgetPrivatePwd" class="safe_btn safe_btn2" :class="editRepeatPsw.length==6?'primary':''">确定</div>
            </div>
        </template>
        <Vcode :show="vcodeShow" @success="verifySuccess" @close="vcodeShow = false" />
    </div>
</template>
  
<script>
    import safeInput from '@/components/safeInput.vue'
    import Vcode from "vue-puzzle-vcode"
    export default {
        components:{
            safeInput,
            Vcode
        },
        name: 'editSafePsw',
        props: {
            showClose: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                userInfo: '',
                code: '', //验证码
                editPsw: '', //修改密码
                editRepeatPsw: '', //再次输入修改密码
                vcodeShow: false,
                safeType: 2,
                countdown: 0
            }
        },
        created(){
            this.userInfo = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user"))))

        },
        methods:{
            handClose(){
                this.$emit('close')
            },
            forgetPrivatePwd(){
                if(this.editRepeatPsw.length < 6) return false
                if(this.editPsw !== this.editRepeatPsw){
                    this.$message.error('两次密码不一致')
                    return false
                }
                let parm = {
                    userName: this.userInfo.userName,
                    password: this.editPsw,
                    code: this.code
                }
                const regex = new RegExp(`^${parm.password[0]}+$`);
                if(this.isSequentialNumber(parm.password)){
                    this.$message.error('密码不能是连续数字')
                    return false
                }
                if(regex.test(parm.password)){
                    this.$message.error('密码不能是连续相同的数字')
                    return false
                }
                if(parm.password.includes(' ')){
                    this.$message.error('密码不能有空格')
                    return false
                }
                this.$api.forgetPrivatePwd(parm).then(res=>{
                    this.$message.success('修改成功')
                    if(!this.showClose){
                        this.handBack()

                    }else{
                        this.handClose()
                    }
                }).catch(res=>{})
            },
            // 校验验证码
            checkCode(){
                if(this.code.length < 4) return false
                let parm = {
                    code: this.code,
                    phone: this.userInfo.userName
                }
                this.$api.checkCode(parm).then(res=>{
                    this.safeType = 3
                }).catch(err=>{})
            },
            handNext(key,max = 6){
                if(this[key] < max){
                    return false
                }
                this.safeType++
            },
            handBack(){
                this.$emit('back')
            },
            handLast(key){
                this[key] = ''
                this.safeType--
            },
            // 验证成功
            verifySuccess(){
            this.vcodeShow = false
            this.countdown = 60
            this.startCountdown()
            this.getCode()

            },
            // 点击获取验证码
            handCode(){
                if(this.countdown > 0){
                    return false
                }
                if(!this.userInfo.userName){
                    this.$message.error('请输入手机号码')
                    return false
                }
                this.vcodeShow = true
            },
            startCountdown(){
                if(this.countdown < 1){
                    return false
                }
                this.countdown--
                setTimeout(()=>{
                    this.startCountdown()
                },1000)
            },
            getCode(){
                this.$api.getCode({phones: this.userInfo.userName}).then(res=>{

                }).catch(res=>{})
            },
        }
    }
</script>
  
<style lang="scss" scoped>
    *{
        box-sizing: border-box;
        line-height: 1;
    }
    .safe_psw{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .safe_common{
        width: 450px;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px;
        .safe_common_title{
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
        }
        .safe_common_subtitle{
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            margin-top: 8px;
        }
        .safe_forget{
            font-weight: 400;
            font-size: 12px;
            color: #016EF1;
            line-height: 17px;
            margin-top: 20px;
            cursor: pointer;
        }
        .common_phone{
            font-weight: bold;
            font-size: 14px;
            color: #333333;
            display: flex;
            align-items: center;
            margin-top: 20px;
            .safe_countdown{
                font-weight: 400;
                font-size: 12px;
                color: #016EF1;
                cursor: pointer;
            }
        }
        .safe_rule{
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            margin-top: 20px;
        }
    }
    .safe_btn{
        width: 100px;
        line-height: 34px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #EAEAEA;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        margin-right: 10px;
        text-align: center;
        cursor: pointer;
        &.safe_btn2{
            background: #CCCCCC;
            color: #FFFFFF;
            border: none;
        }
        &.primary{
            background: #016EF1;
            border: none;
            
        }
    }
    .safe_btn:last-child{
        margin-right: 0;
    }
    .close_icon{
        width: 12px;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
</style>
  