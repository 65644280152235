
// import {api} from '@/util/request'

// const apiRequest = {
//     getRouters(parm){
//         return api.get('/getRouters', parm)
//     }
// }

// export default apiRequest
import jsonToFormData from '@/util/jsonToFormData'


import {api_request} from '@/util/request'

const apiRequest = {
    login(parm){ // 登录
        return api_request({
            url: '/login', 
            method: 'post', 
            parm , 
            isToken: false
        })
    },
    logout(parm){ // 退出登录
        return api_request({
            url: '/logout', 
            method: 'post', 
            parm ,
        })
    },
    editPassword(parm){// 修改密码
        return api_request({
            url: `/system/user/editPassword`, 
            method: 'put',
            parm,
            isToken: false
        })
    },
    forgetPwd(parm){// 忘记密码
        return api_request({
            url: `/sms/forgetPwd`, 
            method: 'put',
            parm,
            isToken: false
        })
    },
    getCode(parm){// 获取短信验证码
        return api_request({
            url: `/sms/sendVerificationCode`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    register(parm){// 提交注册申请
        return api_request({
            url: `/gqmanage/approval`, 
            method: 'post',
            parm,
            isToken: false
        })
    },
    captchaImage(parm){//获取图片验证码
        return api_request({
            url:'/captchaImage',
            method: 'get',
            parm: parm,
            isToken: false
        })
        
    },
    getInfo(parm){//获取用户信息
        return api_request({
            url: '/getInfo',
            method: 'get',
            parm
        })
    },
    editAvatar(parm){ // 编辑用户头像
        return api_request({
            url: '/system/user/editAvatar', 
            method: 'put', 
            parm: jsonToFormData(parm),
        })
    },
    getRouters(parm){//获取导航栏
        return api_request({
            url: '/getRouters', 
            method: 'get',
            parm
        })
    },
    getRoutersNew(parm){//获取导航栏(新)
        return api_request({
            url: '/getRoutersNew', 
            method: 'get',
            parm
        })
    },
    getMapsList(parm){//物料品类
        return api_request({
            url: '/gqManage/matter/treelist', 
            method: 'get',
            parm
        })
    },
    getCompty(parm){//公司下拉
        return api_request({
            url: '/gqManage/factory/list', 
            method: 'get',
            parm
        })
    },
    getDataSerch(parm){//数据中心
        return api_request({
            url: '/gqManage/appointment/data/serch', 
            method: 'get',
            parm
        })
    },
    getDataSerchStat(parm){//新数据中心
        return api_request({
            url: '/appointmentStat/list', 
            method: 'get',
            parm
        })
    },
    getDataorderStat(parm){//新数据中心统计
        return api_request({
            url: '/gqManage/orderPrice/orderStat', 
            method: 'get',
            parm
        })
    },
    getMaterialMap(parm){//物料品类下拉框1
        return api_request({
            url: '/srm/custom/materialMap', 
            method: 'get',
            parm
        })
    },
    getAppointmentList(parm){//起始数据
        return api_request({
            url: '/srm/appointment/list', 
            method: 'get',
            parm
        })
    },
    getFactoryAreaMap(parm){//下拉厂区
        return api_request({
            url: '/gqManage/factoryArea/getFactoryAreaMap', 
            method: 'get',
            parm
        })
    },
    getFactoryMap(parm){//获取公司
        return api_request({
            url: '/gqManage/factory/getFactoryMap', 
            method: 'get',
            parm
        })
    },
    postAppointment(parm,method){//提交预约
        return api_request({
            url: '/srm/appointment', 
            method,
            parm
        })
    },
    appointmentPending(parm,method){//提交预约(客户，未判断权限)
        return api_request({
            url: '/srm/appointment/pending', 
            method,
            parm
        })
    },
    getOrderDetail(parm){//获取订单详情
        return api_request({
            url: '/srm/appointment/order/list', 
            method: 'get',
            parm,
            toast: false
        })
    },
    orderRemove(parm){//取消订单
        return api_request({
            url: '/srm/appointment/remove', 
            method: 'put',
            parm
        })
    },
    personList(parm){//人员列表
        return api_request({
            url: '/srm/person/list', 
            method: 'get',
            parm
        })
    },
    personList1(parm){//人员列表1
        return api_request({
            url: '/srm/person/list1', 
            method: 'get',
            parm
        })
    },
    verifyOreder(parm){//确认出货
        return api_request({
            url: '/srm/appointment/verify/oreder', 
            method: 'put',
            parm
        })
    },
    cargoList(parm){//进出货列表
        return api_request({
            url: '/srm/appointment/list/cargo', 
            method: 'get',
            parm
        })
    },
    cargoDetail(parm){//进出货订单详情
        return api_request({
            url: '/srm/appointment/person/list', 
            method: 'get',
            parm,
            toast: false
        })
    },
    passData(parm){//通行证信息
        return api_request({
            url: '/srm/appointment/produce/pass', 
            method: 'get',
            parm,
            toast: false
        })
    },
    passProofList(parm){//出货凭证信息列表
        return api_request({
            url: '/gqManage/orderDetailed/list/detail', 
            method: 'get',
            parm,
            toast: false
        })
    },
    postVoucher(parm){//上传电子凭证
        return api_request({
            url: '/gqManage/orderDetailed/upload/voucher', 
            method: 'post',
            parm
        })
    },
    gqManageOrderDetail(parm){//物料订单详情
        return api_request({
            url: '/gqManage/orderDetailed/list', 
            method: 'get',
            parm
        })
    },
    postRemarks(parm){//入库修改备注
        return api_request({
            url: '/srm/inboundRecords/update/remarks', 
            method: 'post',
            parm
        })
    },
    deleteStock(parm){//入库列表删除    
        return api_request({
            url: `/srm/inboundRecords/deleteStock/${parm.id}`, 
            method: 'delete',
        })
    },
    inboundRecordsSearch(parm){//获取入库登记列表    
        return api_request({
            url: `/srm/inboundRecords/search`, 
            method: 'get',
            parm
        })
    },
    inboundRecordsCustomer(parm){//获取入库登记列表    
        return api_request({
            url: `/srm/inboundRecords/customer`, 
            method: 'get',
            parm
        })
    },
    inboundRecords(parm){//提交入库登记列表    
        return api_request({
            url: `/srm/inboundRecords`, 
            method: 'post',
            parm
        })
    },
    inboundRecordsCount(parm){//获取入库登记列表    
        return api_request({
            url: `/srm/inboundRecords/count`, 
            method: 'get',
            parm
        })
    },
    outboundRecordsRemarks(parm){//出库修改备注    
        return api_request({
            url: `/srm/outboundRecords/update/remarks`, 
            method: 'post',
            parm
        })
    },
    outDeleteStock(parm){//出库列表删除    
        return api_request({
            url: `/srm/outboundRecords/deleteStock/${parm.id}`, 
            method: 'delete',
        })
    },
    outboundRecordsList(parm){//出库数据列表  
        return api_request({
            url: `/srm/outboundRecords/list`, 
            method: 'get',
            parm
        })
    },
    outboundRecordsSerch(parm){//出库数据列表  
        return api_request({
            url: `/srm/outboundRecords/serch/material`, 
            method: 'get',
            parm
        })
    },
    outboundRecords(parm){//获取入库登记列表    
        return api_request({
            url: `/srm/outboundRecords`, 
            method: 'post',
            parm
        })
    },
    materialsStockList(parm){//入库统计列表  
        return api_request({
            url: `/srm/materialsStock/list`, 
            method: 'get',
            parm
        })
    },
    materialsStock(parm){//入库登记提交    
        return api_request({
            url: `/srm/materialsStock`, 
            method: 'put',
            parm
        })
    },
    outboundRecordsListCount(parm){//出库统计列表  
        return api_request({
            url: `/srm/outboundRecords/list/count`, 
            method: 'get',
            parm
        })
    },
    personDelete(parm){//删除人员  
        return api_request({
            url: `/srm/person/delete/person`, 
            method: 'post',
            parm
        })
    },
    addPerson(parm,method){//新增人员  
        return api_request({
            url: `/srm/person`, 
            method,
            parm
        })
    },
    deptTree(parm){//获取用户管理部门列表  
        return api_request({
            url: `/system/user/deptTree`, 
            method: 'get',
            parm
        })
    },
    tempWeightSaveRow(parm){//保存/编辑 重量  
        return api_request({
            url: `/gqManage/tempWeight/saveRow`, 
            method: 'post',
            parm
        })
    },
    tempWeightDeleteWeight(parm){//
        return api_request({
            url: `/gqManage/tempWeight/deleteWeight`, 
            method: 'get',
            parm
        })
    },
    tempWeightAddTempWeight(parm){//  
        return api_request({
            url: `/gqManage/tempWeight/addTempWeight`, 
            method: 'get',
            parm
        })
    },
    matterList(parm){//  
        return api_request({
            url: `/gqManage/matter/matterList`, 
            method: 'get',
            parm
        })
    },
    bagGetMapList(parm){//  
        return api_request({
            url: `/gqManage/bag/getMapList`, 
            method: 'get',
            parm
        })
    },
    deleteTempweight(parm){// 删除行
        return api_request({
            url: `/gqManage/tempWeight/deleteTempweight/${parm.id}`, 
            method: 'delete'
        })
    },
    submitWeight(parm){// 录入重量
        return api_request({
            url: `/gqManage/tempWeight/submitWeight`, 
            method: 'post',
            parm
        })
    },
    vehicleList(parm){// 获取车辆管理列表
        return api_request({
            url: `/gqManage/vehicle/list`, 
            method: 'get',
            parm
        })
    },
    getVehicleType(parm){// 车辆类型列表
        return api_request({
            url: `/gqManage/vehicle/getVehicleType`, 
            method: 'get',
            parm
        })
    },
    getProvinceCode(parm){// 车牌号省代号列表
        return api_request({
            url: `/gqManage/vehicle/getProvinceCode`, 
            method: 'get',
            parm
        })
    },
    getCityCode(parm){// 车牌号市代号列表
        return api_request({
            url: `/gqManage/vehicle/getCityCode`, 
            method: 'get',
            parm
        })
    },
    postVehicle(parm,method){// 新增车辆
        return api_request({
            url: `/gqManage/vehicle`, 
            method: method,
            parm
        })
    },
    delVehicle(parm){// 新增车辆
        return api_request({
            url: `/gqManage/vehicle/${parm.id}`, 
            method: 'delete',
        })
    },
    getRoleList(parm){// 角色列表
        return api_request({
            url: `/system/role/list`, 
            method: 'get',
            parm
        })
    },
    getUserRoles(parm){// 用户管理 —— 角色列表
        return api_request({
            url: `/system/role/userRoles`, 
            method: 'get',
            parm
        })
    },
    addRole(parm,method){// 创建角色 put为保存角色的菜单详情及角色信息
        return api_request({
            url: `/system/role`, 
            method: method || 'post',
            parm
        })
    },
    editRole(parm){// 编辑角色
        return api_request({
            url: `/system/role/changeRoleName`, 
            method: 'put',
            parm
        })
    },
    delRole(parm){// 删除角色
        return api_request({
            url: `/system/role/${parm.id}`, 
            method: 'delete'
        })
    },
    allocatedList(parm){// 角色成员列表
        return api_request({
            url: `/system/role/authUser/allocatedList`, 
            method: 'get',
            parm
        })
    },
    changeStatus(parm){// 用户状态修改
        return api_request({
            url: `/system/user/changeStatus`, 
            method: 'put',
            parm
        })
    },
    unallocatedList(parm){// 查询不属于该角色的用户列表
        return api_request({
            url: `/system/role/authUser/unallocatedList`, 
            method: 'get',
            parm
        })
    },
    assignUsers(parm){// 批量选择用户授权
        return api_request({
            url: `/system/role/authUser/selectAll?roleId=${parm.roleId}&userIds=${parm.userIds}`, 
            method: 'put',
            parm,
            // contentType: 'multipart/form-data'
        })
    },
    authRole(parm){// 获取用户的角色列表
        return api_request({
            url: `/system/user/authRole/${parm.id}`, 
            method: 'get',
        })
    },
    putAuthRole(parm){// 修改角色
        return api_request({
            url: `/system/user/authRole?roleIds=${parm.roleIds}&userId=${parm.userId}`, 
            method: 'put',
        })
    },
    dataScope(parm){// 用户批量选择角色授权
        return api_request({
            url: `/system/role/dataScope`, 
            method: 'put',
            // contentType: 'multipart/form-data'
            parm
        })
    },
    authUserCancel(parm){// 移除角色成员
        return api_request({
            url: `/system/role/authUser/cancel`, 
            method: 'put',
            parm
        })
    },
    roleMenuTreeselect(parm){// 获取权限树
        return api_request({
            url: `/system/menu/roleMenuTreeselect/${parm.id}`, 
            method: 'get',
        })
    },
    roleMenuTreeselectNew(parm){// 获取权限树（新）
        return api_request({
            url: `/system/menu/roleMenuTreeselectNew/${parm.id}`,
            parm:{
                platformType: parm.platformType
            },
            method: 'get',
        })
    },
    getTrendChart(parm){// 获取趋势图
        return api_request({
            url: `/bigdata/getTrendChart`, 
            method: 'get',
            parm
        })
    },
    getDealInfo(parm){// 获取交易信息
        return api_request({
            url: `/bigdata/getDealInfo`, 
            method: 'get',
            parm
        })
    },
    getMaterials(parm){// 获取物料信息
        return api_request({
            url: `/srm/materials/dept`, 
            method: 'get',
            parm
        })
    },
    getMatter(parm){// 获取饼图
        return api_request({
            url: `/bigdata/getMatter`, 
            method: 'get',
            parm
        })
    },
    getFactory(parm){// 获取排行榜
        return api_request({
            url: `/bigdata/getFactory`, 
            method: 'get',
            parm
        })
    },
    getUserList(parm){// 获取用户列表
        return api_request({
            url: `/system/user/list`, 
            method: 'get',
            parm
        })
    },
    addDept(parm){// 创建部门
        return api_request({
            url: `/system/dept`, 
            method: 'post',
            parm
        })
    },
    editDept(parm){// 编辑部门
        return api_request({
            url: `/system/dept`, 
            method: 'put',
            parm
        })
    },
    addUser(parm){// 新增用户
        return api_request({
            url: `/system/user`, 
            method: 'post',
            parm
        })
    },
    editUser(parm){// 编辑用户
        return api_request({
            url: `/system/user`, 
            method: 'put',
            parm
        })
    },
    delDept(parm){// 删除部门
        return api_request({
            url: `/system/dept/${parm.id}`, 
            method: 'delete'
        })
    },
    delUser(parm){// 删除用户
        return api_request({
            url: `/system/user/${parm.id}`, 
            method: 'delete'
        })
    },
    visitor(parm){// 获取二维码
        return api_request({
            url: `/visitor/${parm.code}`, 
            method: 'get',
        })
    },
    resetPwd(parm){// 重置密码
        return api_request({
            url: `/system/user/resetPwd`, 
            method: 'put',
            parm
        })
    },
    getFindList(parm){// 获取物料品类
        return api_request({
            url: `/gqManage/matter/find/list`, 
            method: 'get',
            parm
        })
    },
    getResponse(parm){// 获取部门负责人
        return api_request({
            url: `/system/user/response`, 
            method: 'get',
            parm
        })
    },
    vehicleTree(parm){// 获取下拉的公车辆列表
        return api_request({
            url: `/gqManage/vehicle/vehicleTree`, 
            method: 'get',
            parm
        })
    },
    deptResponse(parm){// 用户管理权限控制
        return api_request({
            url: `/system/user/deptResponse`, 
            method: 'get',
            parm
        })
    },
    changeSort(parm){// 角色顺序修改
        return api_request({
            url: `/system/role/changeSort`, 
            method: 'put',
            parm: jsonToFormData(parm)
        })
    },
    allDeptTree(parm){// 获取全部的部门树
        return api_request({
            url: `/system/user/allDeptTree`, 
            method: 'get',
            parm
        })
    },
    getDept(parm){// 获取 城市/部门 列表
        return api_request({
            url: `/system/dept/getDept`, 
            method: 'get',
            parm
        })
    },
    getProvinceCityCode(parm){// 根据部门id获取车牌省市代号
        return api_request({
            url: `/gqManage/vehicle/getProvinceCityCode`, 
            method: 'get',
            parm
        })
    },
    getDeptNoAuth(parm){// 获取公司地址
        return api_request({
            url: `/system/user/ssqDeptTree`, 
            method: 'get',
            parm
        })
    },
    checkPlateNumber(parm){// 校验车牌号
        return api_request({
            url: `/gqManage/vehicle/checkPlateNumber`, 
            method: 'get',
            parm
        })
    },
    getCompanyCode(parm){// 获取公司码
        return api_request({
            url: `/visitor/getCompanyCode`, 
            method: 'get',
            parm
        })
    },
    editReject(parm){// 订单驳回
        return api_request({
            url: `/srm/appointment/editReject`, 
            method: 'put',
            parm
        })
    },
    getRecycleList(parm){// 回收站列表
        return api_request({
            url: `/gqmanage/recycle/list`, 
            method: 'get',
            parm
        })
    },
    recRecycle(parm){// 更新回收站接收状态（指派 接收 拒绝）
        return api_request({
            url: `/srm/appointment/recRecycle`, 
            method: 'put',
            parm
        })
    },
    orderEvaluation(parm){// 立即评价
        return api_request({
            url: `/gqmanage/orderEvaluation`, 
            method: 'post',
            parm
        })
    },
    editRecycleStatus(parm){// 修改回收站状态
        return api_request({
            url: `/gqmanage/recycle/editStatus`, 
            method: 'put',
            parm
        })
    },
    editRecycle(parm){// 修改回收站信息
        return api_request({
            url: `/gqmanage/recycle`, 
            method: 'put',
            parm
        })
    },
    getRecycleStat(parm){// 获取回收站统计
        return api_request({
            url: `/gqmanage/recycle/stat`, 
            method: 'get',
            parm
        })
    },
    getDeptProvince(parm){// 获取所属城市
        return api_request({
            url: `/system/dept/province`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    getRecycle(parm){// 获取回收商详情
        return api_request({
            url: `/gqmanage/recycle/getRecycle/${parm.deptId}`, 
            method: 'get',
        })
    },
    getRecycleorderList(parm){// 获取回收商订单列表
        return api_request({
            url: `/srm/appointment/list/recycle`, 
            method: 'get',
            parm
        })
    },
    getRecycleMatterDetail(parm){// 获取回收商物料明细统计
        return api_request({
            url: `/srm/appointment/recycle/matterDetail`, 
            method: 'get',
            parm
        })
    },
    recycleRefresh(parm){// 刷新回收站列表
        return api_request({
            url: `/gqmanage/recycle/refresh`, 
            method: 'get',
            parm
        })
    },
    recycleOrderInfo(parm){// 回收站订单详情
        return api_request({
            url: `/srm/appointment/recycle/orderInfo`, 
            method: 'get',
            parm
        })
    },
    getDeviceList(parm){// 获取设备列表
        return api_request({
            url: `/iot/device/list`, 
            method: 'get',
            parm
        })
    },
    getDeviceStatusDetails(parm){// 获取各个设备状态数量
        return api_request({
            url: `/iot/device/deviceStatusDetails`, 
            method: 'get',
            parm
        })
    },
    postDevice(parm){// 添加设备
        return api_request({
            url: `/iot/device`, 
            method: 'post',
            parm
        })
    },
    editDevice(parm){// 编辑设备
        return api_request({
            url: `/iot/device`, 
            method: 'put',
            parm
        })
    },
    delDevice(parm){// 删除设备
        return api_request({
            url: `/iot/device/${parm.deviceId}`, 
            method: 'delete',
        })
    },
    getDeviceLog(parm){// 获取设备日志记录
        return api_request({
            url: `/deviceLog/deviceLog/list`, 
            method: 'get',
            parm
        })
    },
    getDeviceDetail(parm){// 获取设备详情
        return api_request({
            url: `/iot/device/${parm.deviceId}`, 
            method: 'get',
        })
    },
    getDeviceWeightList(parm){// 获取设备称重记录列表
        return api_request({
            url: `/iot/deviceWeight/list`, 
            method: 'get',
            parm
        })
    },
    getDeviceTotalWeight(parm){// 获取设备总重
        return api_request({
            url: `/iot/deviceWeight/totalWeight`, 
            method: 'get',
            parm
        })
    },
    downDeviceWeight(parm,responseType){// 导出设备称重记录
        return api_request({
            url: `/iot/deviceWeight/export`, 
            method: 'post',
            parm: jsonToFormData(parm),
            responseType
        })
    },
    getCompanyAddress(parm){// 获取公司地址
        return api_request({
            url: `/srm/appointment/selectCustomAddress`, 
            method: 'get',
            parm
        })
    },
    getApprovalList(parm){// 获取审核列表
        return api_request({
            url: `/gqmanage/approval/list`, 
            method: 'get',
            parm
        })
    },
    getApprovalDetail(parm){// 获取审核详情
        return api_request({
            url: `/gqmanage/approval/${parm.id}`, 
            method: 'get',
        })
    },
    approvalEditStatus (parm){// 账号通过或驳回
        return api_request({
            url: `/gqmanage/approval/editStatus`, 
            method: 'put',
            parm
        })
    },
    getInvoiceList(parm){// 获取票据列表
        return api_request({
            url: `/gqmanage/invoice/list`, 
            method: 'get',
            parm
        })
    },
    addInvoice(parm){// 上传票据
        return api_request({
            url: `/gqmanage/invoice`, 
            method: 'post',
            parm
        })
    },
    editInvoice(parm){// 编辑票据
        return api_request({
            url: `/gqmanage/invoice`, 
            method: 'put',
            parm
        })
    },
    delInvoice(parm){// 删除票据
        return api_request({
            url: `/gqmanage/invoice/${parm.id}`, 
            method: 'delete'
        })
    },
    getContractList(parm){// 获取合同列表
        return api_request({
            url: `/gqmanage/contract/list`, 
            method: 'get',
            parm
        })
    },
    addContract(parm){// 新建合同
        return api_request({
            url: `/gqmanage/contract`, 
            method: 'post',
            parm
        })
    },
    editContract(parm){// 编辑合同
        return api_request({
            url: `/gqmanage/contract`, 
            method: 'put',
            parm
        })
    },
    downloadTemplate(parm,responseType){// 下载合同模版
        return api_request({
            url: `/ucase/download/template`, 
            method: 'get',
            parm,
            responseType
        })
    },
    downloadContract(parm,responseType){// 下载合同模版
        return api_request({
            url: `/gqmanage/contract/downloadContract`, 
            method: 'get',
            parm,
            responseType
        })
    },
    getDepts(parm){// 合同管理获取公司
        return api_request({
            url: `/system/dept/getDepts`, 
            method: 'get',
            parm
        })
    },
    uploadContract(parm){// 上传合同
        return api_request({
            url: `/gqmanage/contract/uploadContract`, 
            method: 'put',
            parm
        })
    },
    editContractStatus(parm){// 修改合同状态
        return api_request({
            url: `/gqmanage/contract/editStatus`, 
            method: 'put',
            parm
        })
    },
    getNotificationList(parm){// 获取消息列表
        return api_request({
            url: `/notification/list`, 
            method: 'get',
            parm
        })
    },
    allRead(parm){// 全部已读
        return api_request({
            url: `/notification/editAll`, 
            method: 'put',
            parm
        })
    },
    notificationRead(parm){// 消息已读
        return api_request({
            url: `/notification/${parm.ids}`, 
            method: 'put'
        })
    },
    notificationDel(parm){// 删除消息
        return api_request({
            url: `/notification/${parm.ids}`, 
            method: 'delete'
        })
    },
    getMessageLog(parm){// 获取消息日志
        return api_request({
            url: `/gqmanage/messageLog/list`, 
            method: 'get',
            parm
        })
    },
    getCheckVisitor(parm){// 通行证验证
        return api_request({
            url: `/visitor/checkVisitor/${parm.code}`, 
            method: 'get',
            parm,
            isToken: false
        })
    },
    setPrivatePwd(parm){// 设置密码
        return api_request({
            url: `/system/user/setPrivatePwd`, 
            method: 'put',
            parm,
        })
    },
    checkPrivatePwd(parm){// 验证密码
        return api_request({
            url: `/system/user/checkPrivatePwd`, 
            method: 'get',
            parm,
        })
    },
    checkCode(parm){// 验证验证码
        return api_request({
            url: `/sms/checkVerificationCode`, 
            method: 'get',
            parm,
        })
    },
    forgetPrivatePwd(parm){// 忘记安全密码
        return api_request({
            url: `/sms/forgetPrivatePwd`,
            method: 'put',
            parm,
        })
    },
    isExistsUser(parm){// 检验是否有这个账号
        return api_request({
            url: `/system/user/isExistsUser`,
            method: 'get',
            parm,
            isToken: false
        })
    },
    
}

export default apiRequest