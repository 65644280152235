<template>
  <div class="view_bottom">
    <div class="seize"></div>
    <div class="homes">
      <div class="bigger">
        <!-- 上部位置 -->
        <div class="seized">
          <div class="flex">
            <div class="from_input flex fw_wrap flex1">
              <div class="flex ai_center" style="width: 33%;margin-bottom: 20px;">
                <div class="from_input_title">订单编号</div>
                <el-input class="from_input_box" size="small" clearable v-model="siftData.code"
                placeholder="请输入订单编号" @change="sea"></el-input>
              </div>
              <div class="flex ai_center" style="width: 33%;margin-bottom: 20px;">
                <div class="from_input_title">物料品类</div>
                <el-cascader class="from_input_box fw_nowrap" filterable size="small" placeholder="选择物料品类"
                  :props="defaultParams" :options="options" v-model="siftData.matter" ref="cascaderAddr"
                  @change="sea" collapse-tags clearable></el-cascader>
              </div>
              <div class="flex ai_center" style="width: 33%;margin-bottom: 20px;">
                <div class="from_input_title">出货日期</div>
                <el-date-picker class="from_input_box" @change="sea" size="small" v-model="siftData.times" type="daterange" range-separator="至"
                  start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期">
                </el-date-picker>
              </div>
              <div class="flex ai_center" style="width: 33%;margin-bottom: 20px;">
                <div class="from_input_title">负责人</div>
                <el-input class="from_input_box" size="small" clearable v-model="siftData.responName"
                placeholder="请输入负责人" @change="sea"></el-input>
              </div>
              <div class="flex ai_center" style="width: 33%;margin-bottom: 20px;">
                <div class="from_input_title">厂区</div>
                <el-select class="from_input_box" filterable clearable @change="factoryChange" v-model="siftData.factoryAreaName" size="small"
                  placeholder="请选择厂区">
                  <el-option v-for="item in factor" :key="item.text" clearable :label="item.text" :value="item.text">
                  </el-option>
                </el-select>
              </div>
              <div class="flex ai_center" style="width: 33%;margin-bottom: 20px;">
                <div class="from_input_title">公司</div>
                <el-select class="from_input_box" v-model="siftData.factoryId" filterable clearable size="small" placeholder="请选择公司" @change="sea">
                  <el-option v-for="item in factoryList" :key="item.value" :label="item.text" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <!-- 订单状态 -->
              <div class="flex ai_center order_status">
                <p class="title">订单状态</p>
                <div class="flex ai_center">
                    <el-radio @input="sea" style="margin-right: 20px;" v-model="siftData.radio" label="">全部</el-radio>
                    <el-radio @input="sea" v-model="siftData.radio" style="margin-right:20px;" label="0">待确认</el-radio>
                    <el-radio @input="sea" v-model="siftData.radio" style="margin-right:20px;" label="1">待出货</el-radio>
                    <el-radio @input="sea" v-model="siftData.radio" style="margin-right:20px;" label="2">已出货</el-radio>
                    <el-radio @input="sea" v-model="siftData.radio" style="margin-right:20px;" label="4">已完成</el-radio>
                    <el-radio @input="sea" v-model="siftData.radio" label="3">已取消</el-radio>
                </div>
              </div>
            </div>
            <div class="input_modek_but" style="">
              <el-button type="primary" size="small" style="background-color: #016EF1;border: none;width: 100px;"
                icon="el-icon-search" @click="sea()">查询</el-button>
              <el-button size="small" style="width: 100px;" icon="el-icon-refresh-right"
                @click="resetting()">重置</el-button>
            </div>
          </div>
          
          <el-dialog custom-class="ferd" title="新建预约" :visible.sync="centerDialogVisible" width="730px"
            style="margin-top:12vh;border-radius:20px;" height="400px">
            <div class="pop_form">
              <div class="flex ai_center pop_form_item">
                <div class="pop_form_title">出货日期</div>
                  <el-date-picker class="flex1" :picker-options="pickerOptions" size="small" v-model="valtime"
                    value-format="yyyy-MM-dd HH:mm" type="datetime" placeholder="选择出货日期">
                  </el-date-picker>
              </div>
              <div class="flex ai_center">
                <div class="flex ai_center pop_form_item flex1">
                  <div class="pop_form_title">厂区</div>
                  <el-select style="width: 240px;" v-model="customer" filterable @change="nativeBlurHandler(customer)" ref="select" size="small"
                    placeholder="请选择厂区">
                    <el-option v-for="item in factor" :key="item.value" :label="item.text" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="flex ai_center jc_end pop_form_item flex1">
                  <div class="pop_form_title">公司</div>
                  <el-select style="width: 240px;" v-model="material" filterable clearable size="small" placeholder="请选择公司" @change="getFindList">
                    <el-option v-for="item in regionOption" :key="item.value" :label="item.text" :value="item.value">
                    </el-option>
                  </el-select>
                </div>

              </div>
              <div class="flex ai_center pop_form_item">
                <div class="pop_form_title">负责人电话</div>
                <el-input class="flex1" type="number" size="small" style="width: 490px;" clearable v-model="grossweight" placeholder="请输入负责人电话"></el-input>
              </div>
              <div class="flex ai_center">
                <div class="flex ai_center pop_form_item flex1">
                  <div class="pop_form_title">物料品类</div>
                  <el-select style="width: 240px;" v-model="addcheck" multiple filterable  default-first-option
                    placeholder="选择物料品类" size="small">
                    <el-option v-for="item in findList" :key="item.matterId" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </div>
                <div class="flex ai_center jc_end pop_form_item flex1">
                  <div class="pop_form_title flex1">货物重量(KG)</div>
                  <el-input style="width: 240px;" type="number" size="small" clearable v-model="number" placeholder="请输入货物重量(kg)"></el-input>
                </div>
              </div>
              <div class="fot_box flex jc_end" style="margin-top: 20px;">
                <el-button class="bottom_btn" @click="open()" size="small" >取消</el-button>
                <el-button class="bottom_btn" type="primary" size="small" style="background-color: #016EF1;border:none"
                  @click="push()">提交</el-button>
              </div>
            </div>
          </el-dialog>
        </div>
        <div class="area_content">
          
          <div class="necr_side">
            <div v-if="limitJudge(signList,'srm:appointment:add')">
              <el-button
              class="bottom_btn"
                style="color: #016EF1;border: 1px solid #016EF1;"
                size="small" icon="el-icon-plus" @click="getAppointmentInfo">预约订单</el-button>
              <!-- key() -->
            </div>
          </div>
          <!-- 中部位置 -->
          <div class=" center_nodes">
            <template>
              <el-table stripe :header-cell-style="getRowClass" label-class-name="lisr" size="mini" max-height="500"
                :data="tables" style="width:98%;margin: auto;height: 500px;border-top: none;color:#333333"
                :row-class-name="tableRowClassName">
                <div slot="empty" style="text-align: left;margin-top:50px">
                  <el-empty image="https://genqian.top/img/genqian-pc/kongbaiye.png" description="啊哈，暂无任何数据!" />
                </div>
                <el-table-column prop="data" label="序号" type="index" center>
                </el-table-column>
                <el-table-column label="订单编号" width="90">
                  <template slot-scope="scope">
                    <span v-if="scope.row.code==null">
                      {{'--------'}}</span>
                    <div v-else style="white-space: wrap;">
                      <a style="font-size: 10px;width: 20px !important;overflow: hidden;">{{scope.row.code+"&nbsp;"}}</a>
                      <i style="font-size: 14px;" class="el-icon-copy-document"
                        @click="copy(scope.$index, scope.row)"></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="factoryAreaName" label="厂区">
                </el-table-column>
                <el-table-column prop="name" label="公司">
                </el-table-column>
                <el-table-column prop="customAddress" label="公司地址">
                </el-table-column>
                <el-table-column class="line_3" label="物料品类">
                  
                  <template slot-scope="scope">
                    <div style="position: relative;">
                      <div class="line_3">
                        {{ scope.row.matter }}
                      </div>
                      <el-tooltip 
                        style="position: absolute;top: 0;left: 0;opacity: 0; width: 100%; height: 100%;"
                        placement="top-start">
                        <div slot="content" style="max-width: 200px;">{{scope.row.matter}}</div>
                        <div style="height: 100%; cursor: pointer;">hover 激活</div>
                      </el-tooltip>

                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="重量(kg)">
                  <template slot-scope="scope">
                    <span v-if="scope.row.totalWeight==null">
                      {{'--------'}}</span>
                    <span v-else>{{scope.row.totalWeight}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="responName" label="负责人" width="80">
                </el-table-column>
                <el-table-column label="出货时间">
                  <template slot-scope="scope">
                    <span v-if="scope.row.appointmentTime==null">
                      {{'--------'}}</span>
                    <span v-else> {{scope.row.appointmentTime}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="预约时间">
                </el-table-column>
                <el-table-column label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.condition==0" style="color: #F84A44;font-weight: bold;">
                      {{ ("●") +"&nbsp;"+ '待确认' }}</span>
                    <span v-else-if="scope.row.condition==1" style="color: #FF891F;font-weight: bold;">
                      {{("●") +"&nbsp;"+ '待出货'}}</span>
                    <span v-else-if="scope.row.condition==2" style="color:#18AF7B;font-weight: bold;">
                      {{ ("●") +"&nbsp;"+ '已出货' }}</span>
                    <span v-else-if="scope.row.condition==4" style="color: #016EF1;font-weight: bold;">
                      {{ ("●") +"&nbsp;"+ '已完成' }}</span>
                    <span v-else-if="scope.row.condition==3" style="color: #cccccc;font-weight: bold;">
                      {{ ("●") +"&nbsp;"+ '已取消' }}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column style="color:#016EF1" prop="totalWeight" label="状态">
                </el-table-column> -->
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">详情</el-button>
                    <!-- <el-button size="mini" @click="handleDelete(scope.$index, scope.row)">取消订单</el-button> -->
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
          <!-- 底部位置 -->
          <div class="foot_nodes">
            <div class="foot_black">
              <template>
                <div class="paging">
                  <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 50, 100,200]" :page-size="10" layout="total"
                    :total="this.total" />
                  <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                    :current-page="currentPage" :page-sizes="[10, 50, 100,200]" :page-size="10"
                    layout="prev, pager, next,sizes" :total="this.total" />
                </div>
              </template>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import Clipboard from 'clipboard';
export default {
  data () {
    return {
      siftData:{
        // 订单编号
        code: '',
        // 物料品类
        matter: [],
        // 时间
        times: '',
        //订单状态
        radio: '',
        //负责人
        responName: '',
        //厂区
        factoryAreaName: '',
        //公司
        factoryId: '',

      },
      tables: [],
      currentPage: 1,
      pageSize: 10,
      pageNum: 1,
      total: null,
      centerDialogVisible: false,
      tans: '',
      // 弹框物料品类
      addcheck: '',
      // 弹框出货日期
      valtime: '',
      // 弹框厂区
      customer: '',
      // 弹框公司
      material: '',
      // 弹框负责人电话 
      grossweight: '',
      //  弹框货物重量
      number: '',
      infor: [],
      factor: [],
      // 选中厂区id
      factoryAreaId: '',
      // 公司下拉
      gerfacs: [],
      // 公司
      regionOption: [],
      factoryList: [],
      tableLoading: false,
      addShopName: [],
      options: [],
      beforeMerchantId: '',
      defaultParams: {
        label: 'label',
        value: 'label',
        children: 'children',
        multiple: true
      },
      signList: [],
      findList: [],
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
      userId: ''
    }
  },
  created () {
    if(localStorage.getItem("userId")){
      this.userId = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userId"))))
      this.signList = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("permissions"))))
    }
    // 判断是否登录
    if (localStorage.getItem('token') == null) {
      this.$router.push('/page')
    }
    // 初始全部数据
    this.AreaList()
    //物料数据
    this.mapsList()
    // 厂区下拉
    this.company()
    // 下拉公司
    this.factory('init')

  },
  methods: {
    // 获取用户缓存的预约信息
    getAppointmentInfo(){
      this.centerDialogVisible = true
      if(localStorage.getItem(`appointmentInfo${this.userId}`)){
        let appointmentInfo = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem(`appointmentInfo${this.userId}`))))
        this.material = appointmentInfo.material
        this.number = appointmentInfo.number
        this.addcheck = appointmentInfo.addcheck
        this.grossweight = appointmentInfo.grossweight
        this.customer = appointmentInfo.customer
        this.factoryAreaId = appointmentInfo.customer
        // 下拉公司
        // this.factory()
        // 下拉物料品类
        this.getFindList()
      }

    },
    // 获取弹窗下拉物料列表
    async getFindList(e){
      console.log(e)
      if(e){
        this.addcheck = []

      }
      try{
        let res = await this.$api.getFindList({customId: this.material})
        this.findList = res.data

      }catch{
        this.findList = []
      }
    },
    // 详情跳转
    handleEdit (a, b) {
      this.$router.push({
        name: 'Notice',
        query: { appointmentId: b.appointmentId }
      })
    },
    //设置表格第一行的颜色
    getRowClass ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return 'background:#EEEEEE ;color:#666666'
      } else {
        return ''
      }
    },
    // 颜色
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row;color:#333333';
      } else if (rowIndex === 3) {
        return 'success-row;color:#333333';
      }
      return '';
    },
    // 页码
    handleSizeChange (val) {
      // this.input = ''
      // this.addShopName = ''
      // this.times = ''
      // this.radio = ''
      // console.log(val);
      this.pageSize = val
      if ((this.siftData.code || this.siftData.matter || this.siftData.times || this.siftData.radio) == "") {
        this.AreaList()
      } else {
        this.sea()
      }
    },
    // 页码切换
    handleCurrentChange (val) {
      // console.log(val);
      this.pageNum = val
      if ((this.siftData.code || this.siftData.matter || this.siftData.times || this.siftData.radio) == "") {
        this.AreaList()
      } else {
        this.sea()
      }
    },
    // 起始数据
    AreaList () {
      this.$api.cargoList({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          orderId: 2

      }).then(res=>{
        this.total = res.total
        this.tables = res.rows
        
      }).catch(res=>{

      })
    },
    //物料品类
    mapsList () {
      this.$api.getMaterials({deptType: 4}).then(res=>{
        this.options = res.data

      })
    },
    handleChange (val) {
      console.log(val);
    },
    // 下拉厂区https://genqian.top/test/prod-api
    company () {
      this.$api.getFactoryAreaMap().then(res=>{
        this.factor = res.data

      })
    },
    // 厂区触发事件
    nativeBlurHandler (a) {
      console.log(a);
      this.material = ''
      this.factoryAreaId = a
      this.addcheck = []
      this.findList = []
      this.factory()
    },
    factoryChange(e){
      let id
      this.factor.forEach((item,index)=>{
        if(item.text == e){
          id = item.value
        }
      })
      this.sea()
      this.factory('sea',id)
    },
    // 通过id获取公司
    factory (type,id) {
      let factoryAreaId = type=='sea'? id:this.factoryAreaId
      this.$api.getFactoryMap({
        factoryAreaId
      }).then(res=>{
        if(type == 'init'){
          this.regionOption = res.data
          this.factoryList = res.data

        }else if(type=='sea'){
          this.factoryList = res.data
          
        }else{
          this.regionOption = res.data
          
        }

      })
    },
    // 物料下拉联动
    splitChange (a) {
      // console.log(a);
      // console.log(this.addShopName);
    },
    handleCascader (e, form, thsAreaCode) {
      this.$nextTick(() => {
        const checkedNodes = this.$refs['cascaderAddr'].getCheckedNodes() // 获取当前点击的节点
        this.shopNameTwo = checkedNodes[0].data.levelName;//最后一级的value值
        this.shopName = checkedNodes[0].pathLabels[0];//一级名
        this.needtypeId = checkedNodes[0].data.typeId;//最后一级label值
        //循环选中每一级的value值 并用 >> 隔开
        this.titleName = checkedNodes[0].pathLabels.join(">>")
      })
    },
    // 查询
    sea () {
      if (this.siftData.times == null) {
        this.siftData.times = ''
      }
      if (this.siftData.matter == '') {
        this.siftData.matter = ''
      } else {
        var list = []
        this.siftData.matter.forEach(item => {
          list.push(item.slice(-1)[0])
        })
      }
      if (list == undefined) {
        list = ''
      }
      this.$api.cargoList({
        code: this.siftData.code,
        condition: this.siftData.radio,
        matter: list.toString(),
        appointmentDateFrom: this.siftData.times[0],
        appointmentDateTo: this.siftData.times[1],
        responName: this.siftData.responName,
        factoryAreaName: this.siftData.factoryAreaName,
        factoryId: this.siftData.factoryId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then(res=>{
        this.total = res.total
        this.tables = res.rows
        
      }).catch(res=>{
        
      })

    },
    // 重置
    resetting () {
      this.siftData.code = ''
      this.siftData.matter = []
      this.siftData.times = []
      this.siftData.radio = ''
      this.siftData.responName = ''
      this.siftData.factoryAreaName = ''
      this.siftData.factoryId = ''
      this.factory('sea','')
      this.AreaList()
    },
    // 复制
    copy (a, b) {
      // console.log(b.code);
      var input = document.createElement("input"); // 创建input对象
      input.value = b.code; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功！');
    },
    // 弹框提交
    push () {
      if (this.addcheck == '') {
        this.addcheck = ''
      } else {
        var lists = this.addcheck
        // var lists = []
        // this.addcheck.forEach(item => {
        //   lists.push(item.slice(-1)[0])
        // })
      }
      if (lists == undefined) {
        lists = ''
      }
      // /\s/.test(str)
      if(!this.valtime){
        this.$message.error('请选择出货日期');
        return false
      }
      if(!this.customer){
        this.$message.error('请选择厂区');
        return false
      }
      if(!this.material){
        this.$message.error('请选择公司');
        return false
      }
      if(!this.grossweight){
        this.$message.error('请输入负责人电话');
        return false
      }
      if(!this.addcheck){
        this.$message.error('请选择物料品类');
        return false
      }
      if(!this.number){
        this.$message.error('请输入货物重量');
        return false
      }
      this.$api.postAppointment({
          appointmentTime: this.valtime,
          factoryId: this.material,
          totalWeight: this.number,
          matter: lists.toString(),
          contactNumber: this.grossweight
        
      },'post').then(res=>{
        if (res.code == 200) {
          this.$message({
            message: '预约成功',
            type: 'warning'
          });
          let appointmentInfo = {
            material: this.material,
            number: this.number,
            addcheck: this.addcheck,
            grossweight: this.grossweight,
            customer: this.customer
          }
          localStorage.setItem(`appointmentInfo${this.userId}`, window.btoa(window.encodeURIComponent(JSON.stringify(appointmentInfo))));

          this.centerDialogVisible = false
          this.customer = ''
          this.valtime = ''
          this.material = ''
          this.addcheck = ''
          this.grossweight = ''
          this.number = ''
          
          this.$router.push({
            name: 'Notice',
            query: { appointmentId: res.data }
          })
          // this.regionOption = []
          // this.AreaList()
        } else {
          // this.$message('预约失败,请联系管理员');
        }

      }).catch(()=>{

      })
    },
    // 弹框取消
    open () {
      this.centerDialogVisible = false
      this.customer = ''
      this.valtime = ''
      this.material = ''
      this.addcheck = ''
      this.grossweight = ''
      this.number = ''
      // this.regionOption = []
    },
    // 打开弹框
    // key () {
    //   this.customer = ''
    //   this.valtime = ''
    //   this.material = ''
    //   this.addcheck = ''
    //   this.grossweight = ''
    //   this.number = ''
    //   this.regionOption = []
    // },
    // 补充
    buchong () {

    }
  },


}
</script>

<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.bigger {
  height: 720px;
  width: 100%;
  // background-color: rgba(195, 206, 206, 0.493);
}
.seized {
  width: 100%;
  /* background-color: rgba(51, 51, 202, 0.486); */
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 20px;
}
/* 顶部加 */
.necr_side_ones {
  height: 10px;
  width: 100%;
}
/* 一行 */
.necr_side {
  width: 99%;
  padding: 0 0 20px 20px;
  /* background-color: rgba(146, 89, 89, 0.555); */
  display: flex;
  justify-content: space-between;
}
.input_modek_seize {
  height: 40px;
  width: 300px;
  /* background-color: aqua; */
}
.input_modek_seize span {
  height: 40px;
  width: 80px;
  float: left;
  display: block;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  /* background-color: blue; */
}
.input_modek_seize .el-input {
  height: 35px;
  width: 220px;
  // /* background-color: brown; */
  float: left;
  margin-top: 4px;
}
.input_modek_seize .el-select {
  height: 35px;
  width: 220px;
  /* background-color: brown; */
  float: left;
  margin-top: 4px;
}
.input_modek_seize .el-date-picker {
  height: 35px;
  width: 440px;
  margin-top: 4px;
  background-color: rgb(105, 30, 30);
  float: left;
}
/* 第二行 */
.input_modek_se {
  height: 40px;
  width: 680px;
  /* background-color: blueviolet; */
}
.input_modek_se span {
  height: 40px;
  width: 80px;
  float: left;
  display: block;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  // background-color: blue;
}
.input_modek_se .el-radio {
  height: 40px;
  width: 50px;
  line-height: 40px;
  color: #666666;
  font-size: 14px !important;
  // background-color: brown;
  // float: left;
}
.input_modek_but {
  // background-color: blueviolet;
  display: flex;
  justify-content: space-evenly;
}
.input_modek_but button {
  width: 100px;
  height: 32px;
}
/* 中部 */
.center_nodes {
  min-height: 500px;
  width: 100%;
  // margin-top: 20px;
  /* background-color: rgba(170, 187, 179, 0.295);   */
}

/* ********** */
::v-deep .el-table__row > td {
  /* 去除表格线 */
  border: none !important;
}
/* 底部 */
.foot_nodes {
  height: 50px;
  width: 100%;
  // background-color: rgba(236, 229, 229, 0.658);
  /* margin-top: 5px; */
}
.paging {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}
.foot_black {
  height: 30px;
  width: 100%;
  line-height: 40px;
  padding-top: 15px;
  margin: auto;
  // background-color: rgba(179, 40, 40, 0.658);
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #016ef1; // 进行修改选中项背景和字体
    color: #fff;
  }
}
/* 空间 */
.view_bottom {
  height: calc(100vh - 50px);
  width: 100%;
  min-width: 1200px;
  min-height: 750px;
  // background-color: #2c0404;
}
/* 占位 */
.seize {
  height: 15px;
  width: 100%;
}
.homes {
  height: calc(100vh - 80px);
  width: 98%;
  min-width: 1170px;
  min-height: 720px;
  border-radius: 5px;
  // background-color: white;
  margin: auto;
}
//
.ferd {
  margin-top: 28vh !important;
  width: 40%;
  height: 500px !important;
  border-radius: 20px;
}
// 备注框
.center {
  height: 50px;
  width: 500px;
  background-color: white;
}
.notes {
  height: 200px;
  width: 100%;
  // background-color: aliceblue;
}
.notes_head {
  height: 15px;
  width: 20%;
  line-height: 10px;
  font-size: 16px;
  font-weight: bold;
  transform: translateY(-20px);
  color: black;
}

.notes_center {
  height: 100px;
  width: 100%;
  // background-color: blueviolet;
  margin-top: 10px;
}
.notes_bottom {
  height: 60px;
  width: 104%;
  margin: 30px auto;
  // background-color: blue;
  .el-button {
    float: right;
    margin-right: 15px;
    margin-top: 10px;
  }
}
::v-deep .el-radio__label {
  padding-left: 5px !important;
}

// 输入框颜色
::v-deep .el-input__inner {
  // 设置光标颜色
  caret-color: #016ef1;
}
::v-deep .el-input__inner:focus {
  // el-input输入时设置边框颜色
  border: #016ef1 1px solid;
}
// 下拉选择
::v-deep .el-input.is-focus .el-input__inner {
  caret-color: #016ef1;
  border: 1px solid #016ef1 !important;
  transition: all 0.3s;
}
// 日期选择框
::v-deep
  .el-date-editor
  .el-range-editor
  .el-input__inner
  .el-date-editor--daterange
  .el-range-editor--small
  .is-active {
  border: 1px solid #016ef1 !important;
}
::v-deep .el-input__inner.is-active {
  background-color: transparent !important;
  border: 0.5px solid #016ef1 !important;
}
.from_input {
  font-size: 14px;
}

.from_input_title {
  margin-right: 8px;
  width: 70px;
  text-align: right;
}

.from_input_box {
  width: 300px !important;
}
.area_content{
  background: #fff;
  border-radius: 10px;
  padding: 20px 0;
}
::v-deep .from_input_box.fw_nowrap .el-cascader__tags{
  flex-wrap: nowrap;
}
.order_status{
  .title{
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    margin-right: 16px;
  }
}

.pop_form_item{
  margin-top: 20px;
}
.pop_form_title{
  width: 70px;
  text-align: right;
  margin-right: 8px;
}
::v-deep .el-dialog__body{
  padding: 0 20px 20px;
}
::v-deep .el-dialog__header{
  padding-bottom: 0px;
}
</style>