import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/global.css'
import vueToPdf from 'vue-to-pdf'
Vue.use(vueToPdf)
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import Print from 'vue-print-nb'
Vue.use(Print)

Vue.use(ElementUI)
Vue.config.productionTip = false
import axios from 'axios'
Vue.prototype.axios = axios
//封装接口的方法
import apiRequest from '@/api/index'
Vue.prototype.$api = apiRequest
//判断标识符的方法
import limitJudge from '@/util/includes'
Vue.prototype.limitJudge = limitJudge
//退出登录
import logout from '@/util/logout'
Vue.prototype.$logout = logout
//json对象转表单
import jsonToFormData from '@/util/jsonToFormData'
Vue.prototype.$jsonToFormData = jsonToFormData
//遍历数组所有子级
import findItemById from '@/util/findItemById'
Vue.prototype.findItemById = findItemById
//接口域名
import {SITE_URL_API,FILE_SIZE} from '@/config/site'
Vue.prototype.api_url = SITE_URL_API
// 图片大小限制
Vue.prototype.file_size = FILE_SIZE
//通用方法
import {
	addDotToThirdPosition,
	getScore,
	hideMiddleFour,
	findData,
	mergeObj,
	getDate,
	isSequentialNumber,
} from '@/util/universalMethod'
Vue.prototype.addDotToThirdPosition = addDotToThirdPosition
Vue.prototype.getScore = getScore
Vue.prototype.hideMiddleFour = hideMiddleFour
Vue.prototype.findData = findData
Vue.prototype.mergeObj = mergeObj
Vue.prototype.getDate = getDate
Vue.prototype.isSequentialNumber = isSequentialNumber

window.addEventListener('unhandledrejection', function(event) {
	// 处理Promise的reject错误，例如显示错误提示或记录错误信息
	event.preventDefault(); // 阻止默认行为，防止错误继续传播
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
